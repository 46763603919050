<script setup>
import {computed, watch} from 'vue'
	import { useCartStore } from '../store/cart'
	import { useProductStore } from '../store/products'
	import CartItem from '@/components/CartItem'
  import Informer from "@/components/UI/Informer.vue";
  import router from "@/router/router";
import "./Cart.vue";

	const cartStore = useCartStore();
	const productStore = useProductStore();
	const formattedCart = computed(() => cartStore.formattedCart);
	const emit = defineEmits(['change', 'changeDeliveryType']);

	function changeDeliveryType(val) {
	  cartStore.isDelivery = val == 'delivery' ? true : false;
	}
	cartStore.isDelivery = true;
	const myOptions = {
	  config: {
	    preSelected: 'delivery',
	    disabled: false,
	    items: [
	        { name: 'Доставка от 900 руб. ', value: 'delivery', color: 'white', backgroundColor: 'red' },
	        { name: 'Навынос от 600 руб', value: 'pickup', color: 'white', backgroundColor: 'green' }
	    ]
	  }
	};
  let tg = window.Telegram.WebApp;
  tg.BackButton.show();
  tg.MainButton.text = "Оформить "+cartStore.total +" ₽";
  tg.MainButton.isVisible = true;
  tg.MainButton.color = "#b61918";
  tg.MainButton.textColor = "#fff";
  tg.themeParams.secondary_bg_color ="#191918";
  tg.setBackgroundColor("#191918");
  tg.enableClosingConfirmation();
  tg.MainButton.onClick(function() {
    tg.HapticFeedback.impactOccurred('medium');
    if(cartStore.isDelivery) {
      router.push('/checkout/delivery');
    } else {
      router.push('/checkout/pickup');
    }
  });
  tg.BackButton.onClick(function() {
    tg.HapticFeedback.impactOccurred('soft');
      router.back();
  });
  watch(
      () => cartStore.total,
      () => {
        tg.MainButton.text = "Корзина "+cartStore.total +" ₽";
      },
  )
</script>
<template>

<div class="MobileCartContentLayout_root">
	<div class="MobileCartContentLayout_contentWrapper">
		<div class="MobileCartContentLayout_cartTitleRoot">
			<h1 class="UiKitText_root UiKitText_Title3Loose UiKitText_Bold UiKitText_Text"><div>Заказ</div></h1></div>
		<div class="CartItems_root" v-if="formattedCart.length > 0">
			<switcher
				:titleValue="'Получение'"
				:defaultValue="'delivery'"
				:group="'deliverySwitch'"
				:options='myOptions'
				@change="changeDeliveryType($event.value)"
			/>
      <!--<informer id="inform" v-if="cartStore.total >= 1500" v-bind:text="'За данный заказ вам в подарок будет добавлен Салат-боул Цезарь мини.'"/>-->
      <br>
			<div data-count="1">
        <!--<div v-if="cartStore.total >= 1500" class="MobileCartItemLayout_root MobileCartItemLayout_last"><div data-v-6428cdfa="" class="UiKitProductCardRow_root" style="background: #282827;"><div data-v-6428cdfa="" class="UiKitProductCardRow_imageWrapper"><div data-v-6428cdfa="" class="SmartImage_root MobileCartPageItem_itemImageWrapper SmartImage_hideCover"><div data-v-6428cdfa="" class="SmartImage_cover"></div><img data-v-6428cdfa="" src="https://xn--80aaeh6cfw1g.xn--p1ai/storage/2022/05/22/7128c9156ec962a26660fd8a9a6c703d3117ffbc.jpg" class="SmartImage_image SmartImage_coverFitImg SmartImage_visibleImg SmartImage_animatedImg" data-testid="smart-image-img" loading="lazy"></div></div><div data-v-6428cdfa="" class="UiKitProductCardRow_info"><div data-v-6428cdfa="" class="UiKitProductCardRow_name UiKitText_root UiKitText_Caption1 UiKitText_Regular UiKitText_Text UiKitText_withLineClamp" style="-webkit-line-clamp: 2;">Салат-боул Цезарь мини</div><div data-v-6428cdfa="" class="UiKitProductCardRow_priceRow"><span data-v-6428cdfa="" class="UiKitPrice_root UiKitPrice_s UiKitPrice_default UiKitProductCardRow_price UiKitText_root UiKitText_Body2 UiKitText_Medium UiKitText_Text">В подарок!</span></div></div></div></div>-->
				<cart-item
			        v-for="(cartProduct, index) in formattedCart"
			        :key="index"
			        :cart-product="cartProduct"
			    />
			</div>
			<div class="MobileCartFooterLayout_root" v-if="false">
				<div class="MobileCartPageFooter_cartBar">
					<div class="MobileCartPageFooter_buttonWrap">
						<div class="MobileCartPageFooter_cartButtonContent">
							<div class="MobileCartPageFooter_cartButtonPrice"><span class="Price_root">{{ cartStore.total }} ₽</span></div>
						</div>
            <router-link :to="{ name: 'checkout', params: { type: cartStore.isDelivery ? 'delivery' : 'pickup' } }">
						<button class="UiKitButton_root UiKitButton_size-l UiKitButton_variant-action UiKitButton_shape-default MobileCartButton_root MobileCartPageFooter_button"><span>Верно, оформить</span></button>
            </router-link>
          </div>
				</div>
			</div>
		</div>
		<div class="text-empty" v-else>
			<p>Ваша корзина пуста. Положите товары в корзину, чтобы оформить заказ.</p>
		</div>
	</div>
</div>
</template>


<script>

	export default {
		components: {
			CartItem,
      Informer
		},
		methods: {
			DeliveryTypeChange(itemVal) {
				//cartStoreC.isDelivery = itemVal == 'delivery' ? true : false; 
			}
		}
	}
</script>

<style scoped>
.CartItems_root {
	margin-bottom: 50px;
}
.UiKitButton_shape-default.UiKitButton_size-m, .UiKitButton_shape-default.UiKitButton_size-l {
  border-radius: 16px;
}
select.formkit-input option {
  color: white;
}
.UiKitButton_root {
  color: #E0DEDA;
  border: none;
  display: flex;
  position: relative;
  font-size: 16px;
  transition: all 0.2s ease-out;
  align-items: center;
  line-height: 17px;
  justify-content: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.MobileCartPageFooter_button {
  height: auto;
  padding: 8px 11px;
  z-index: 1;
  min-height: 56px;
  border-radius: 16px;
}
.MobileCartPageFooter_button > span {
  display: flex;
  min-height: 1em;
  white-space: unset;
  justify-content: center;
}
.MobileCartButton_root > span {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: visible;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  line-height: normal;
}
.MobileCartButton_root {
  width: 100%;
  display: block;
  padding: 0 16px;
  overflow: visible;
}
.UiKitButton_variant-action {
  color: #21201F;
  font-weight: 500;
  background-color: #FCE000;
}
.MobileCartPageFooter_cartButtonTime {
  font-size: 13px;
  margin-top: 3px;
  font-weight: 500;
  line-height: 15px;
  white-space: nowrap;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.MobileCartPageFooter_cartButtonPrice {
  position: relative;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  white-space: nowrap;
  letter-spacing: -0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.MobileCartPageFooter_cartButtonContent {
  display: flex;
  align-items: flex-start;
  margin-right: 35px;
  flex-direction: column;
  justify-content: center;
}
.MobileCartPageFooter_buttonWrap {
  padding-bottom: 8px;
  display: flex;
  padding: 8px 8px 8px 16px;
    padding-bottom: 8px;
  padding-bottom: max(var(--safe-area-inset-bottom, 8px), 8px);
}
.MobileCartPageFooter_cartBar {
  display: flex;
  flex-direction: column;
}
.MobileCartFooterLayout_root {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: fixed;
  box-shadow: 0px -4px 20px rgba(0,0,0,0.4);
  border-radius: 24px 24px 0 0;
  background-color: #42413E;
}
.text-empty {
	display: flex;
	flex: 1;
	padding-left: 15px;

}
.MobileCartContentLayout_root {
  min-width: 100vw;
  background: #000000;
  margin-top: 0;
}
.MobileCartContentLayout_contentWrapper {
  background: #191918;
  padding-bottom: 50px;
}
.MobileCartContentLayout_cartTitleRoot {
  display: flex;
  padding: 14px 15px 6px 16px;
  background: #191918;
  align-items: center;
  border-radius: 24px 24px 0 0;
  justify-content: space-between;
}
.UiKitText_Text {
  color: #E0DEDA;
}
.UiKitText_Bold {
  font-weight: 700;
}
.UiKitText_Title3Loose {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.015em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

</style>